export const CHANGE_EMAIL_FORM = "change_email_form";
export const COLLECTION_EDIT_DETAILS_FORM = "collection_edit_details_form";
export const COLLECTION_MANAGE_VEHICLES_FORM =
  "collection_manage_vehicles_form";
export const COLLECTOR_REMOVE_FORM = "collector_delete_form";
export const COLLECTOR_EDIT_FORM = "collector_edit_form";
export const CONCIERGE_EDIT_FORM = "concierge_edit_form";
export const CONFIRM_FORM = "confirm_form";
export const DEALER_EDIT_FORM = "dealer_edit_form";
export const HISTORY_RECORD_FORM = "history_record_form";
export const LIST_VIEW_FORM = "list_view_form";
export const LISTING_COMPLETE_NEGOTIATION_FORM =
  "listing_complete_negotiation_form";
export const LISTING_DATA_ROOM_DOCUMENTS_FORM =
  "listing_data_room_documents_form";
export const LISTING_DATA_ROOM_EXTERNAL_LINKS_FORM =
  "listing_data_room_external_links_form";
export const LISTING_DATA_ROOM_GALLERY_FORM = "listing_data_room_gallery_form";
export const LISTING_DELIST_FORM = "listing_delist_form";
export const LISTING_DETAILS_FORM = "listing_details_form";
export const LISTING_SHOW_INTEREST_FORM = "listing_show_interest_form";
export const LISTING_VEHICLE_FORM = "listing_vehicle_form";
export const LOCATION_EDIT_DETAILS_FORM = "location_edit_details_form";
export const LOCATION_MANAGE_VEHICLES_FORM = "location_manage_vehicles_form";
export const MEDIA_UPDATE_FORM = "media_update_form";
export const MEDIA_ASSIGN_TO_HISTORY_RECORD_FORM =
  "media_assign_to_history_record_form";
export const MEDIA_ASSIGN_TO_VEHICLE_FORM = "media_assign_to_vehicle_form";
export const MFA_CONFIRM_FORM = "mfa_confirm_form";
export const MFA_ENABLE_FORM = "mfa_enable_form";
export const NEGOTIATION_MESSAGE_BOARD_FORM = "negotiation_message_board_form";
export const PAYMENT_METHOD_ADD_FORM = "payment_method_add_form";
export const REMINDER_FORM = "reminder_form";
export const SERVICE_FORM = "service_form";
export const SERVICE_REQUEST_FORM = "service_request_form";
export const SERVICE_REQUEST_MESSAGE_BOARD_FORM =
  "service_request_message_board_form";
export const TAG_CREATE_FORM = "tag_create_form";
export const TAG_RENAME_FORM = "tag_rename_form";
export const USER_ACCOUNT_DELETE_FORM = "user_account_delete_form";
export const USER_CHANGE_PASSWORD_FORM = "user_change_password_form";
export const USER_LOGIN_FORM = "user_login_form";
export const USER_NEWSLETTER_SIGNUP_FORM = "user_newsletter_signup_form";
export const USER_ONBOARDING_DETAILS_FORM = "user_onboarding_details_form";
export const USER_PRIVATE_DATA_FORM = "user_private_data_form";
export const USER_PROFILE_FORM = "user_profile_form";
export const USER_REGISTER_FORM = "user_register_form";
export const USER_RESET_PASSWORD_FORM = "user_reset_password_form";
export const USER_VERIFICATION_FORM = "user_verification_form";
export const VEHICLE_ADD_ODOMETER_READING_FORM =
  "vehicle_add_odometer_reading_form";
export const VEHICLE_ADD_VALUATION_PRICE_FORM =
  "vehicle_add_valuation_value_form";
export const VEHICLE_ASSIGN_ORGANIZATION_FORM =
  "vehicle_assign_organization_form";
export const VEHICLE_DISTANCE_UNIT_FORM = "vehicle_distance_unit_form";
export const VEHICLE_EDIT_DETAILS_FORM = "vehicle_edit_details_form";
export const VEHICLE_EDIT_SPECS_FORM = "vehicle_edit_specs_form";
export const VEHICLE_EDIT_TECHNICAL_DATA_FORM =
  "vehicle_edit_technical_data_form";
export const VEHICLE_EDIT_VEHICLE_DATA_FORM = "vehicle_edit_vehicle_data_form";
export const VEHICLE_MANAGE_COLLECTIONS_FORM =
  "vehicle_manage_collections_form";
export const VEHICLE_PRICE_CURRENCY_FORM = "vehicle_price_currency_form";
export const VEHICLE_PRICE_POINT_FORM = "vehicle_price_point_form";
export const VEHICLE_SET_EXTERNAL_LINKS_FORM =
  "vehicle_set_external_links_form";
export const VEHICLE_STATUS_FORM = "vehicle_status_form";
export const VEHICLE_TAGS_FORM = "vehicle_tags_form";
export const VEHICLE_UPDATE_LOCATION_FORM = "vehicle_update_location_form";
export const VEHICLE_VERIFICATION_FORM = "vehicle_verification_form";
export const WISHLIST_ADD_FORM = "wishlist_add_form";
export const WISHLIST_EDIT_SPECS_FORM = "wishlist_edit_specs_form";
