import { memo } from "react";
import { useResolve } from "react-jpex";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { LOGIN } from "constants/routes";
import { AuthenticationService } from "typings/AuthenticationService";
import { LocationState } from "typings/LocationState";
import { Role } from "typings/Role";
import { UserTopics } from "typings/Topics";

type AuthenticatedGuardProps = {
  roles: boolean | Role[];
};

export const AuthenticatedGuard = memo(function AuthenticatedGuard({
  roles,
}: AuthenticatedGuardProps) {
  const { pathname } = useLocation();

  const authService = useResolve<AuthenticationService>(
    "AuthenticationService",
  );

  const isAuthenticated = authService.isAuthenticated();

  if (!isAuthenticated || !roles) {
    PubSub.publish(UserTopics.Unauthorized);
    return (
      <Navigate state={{ from: { pathname } } as LocationState} to={LOGIN} />
    );
  }

  const role = authService.getRole();

  if (Array.isArray(roles)) {
    const set = new Set<Role>(roles);

    const hasRole = role && set?.has(role);

    if (role && hasRole) {
      return (
        <SuspenseWrapper>
          <Outlet />
        </SuspenseWrapper>
      );
    }
  } else if (role && roles === true) {
    return (
      <SuspenseWrapper>
        <Outlet />
      </SuspenseWrapper>
    );
  }

  return <Navigate to={LOGIN} />;
});
