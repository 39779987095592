import {
  DEFAULT_APP_THEME,
  DEFAULT_DASHBOARD_FILTER_SETTINGS,
  DEFAULT_DENSITY_SETTINGS,
  DEFAULT_LISTING_FILTER_SETTINGS,
  DEFAULT_LOGBOOK_FILTER_SETTINGS,
  DEFAULT_NEGOTIATIONS_FILTER_SETTINGS,
  DEFAULT_SERVICE_REQUESTS_FILTER_SETTINGS,
  DEFAULT_SORTING_SETTINGS,
  DEFAULT_USAGE_HISTORY_FILTER_SETTINGS,
  DEFAULT_VEHICLE_FILTER_SETTINGS,
  DEFAULT_VEHICLE_GRID_COLUMNS_VISIBILITY,
} from "constants/settings";
import { AppTheme } from "typings/AppTheme";
import { BillingMethod, BillingPeriod, BillingSummary } from "typings/Billing";
import { DensityScope, DensitySettings, validDensities } from "typings/Density";
import {
  DashboardFilterSettings,
  ListingFilterSettings,
  LogbookFilterSettings,
  NegotiationsFilterSettings,
  RemindersFilterSettings,
  ServiceRequestsFilterSettings,
  UsageHistoryFilterSettings,
  VehicleFilterSettings,
} from "typings/Filter";
import { SortingScope, SortingSettings } from "typings/Sorting";
import { TierInfo } from "typings/Tier";
import { UserPreferenceService } from "typings/UserPreferenceService";
import { VehicleGridColumnsVisibilitySettings } from "typings/Visibility";

const BILLING_KEY = "@@driven-subscribe-config-billing";
const DENSITY_KEY = "@@driven-density-settings";
const FILTER_DASHBOARD_SETTINGS_KEY = "@@driven-dashboard-filter-settings";
const FILTER_LISTING_SETTINGS_KEY = "@@driven-listing-filter-settings";
const FILTER_LOGBOOK_SETTINGS_KEY = "@@driven-logbook-filter-settings";
const FILTER_NEGOTIATIONS_SETTINGS_KEY =
  "@@driven-negotiations-filter-settings";
const FILTER_REMINDERS_SETTINGS_KEY = "@@driven-reminders-filter-settings";
const FILTER_SERVICE_REQUESTS_SETTINGS_KEY =
  "@@driven-service-requests-filter-settings";
const FILTER_SETTINGS_KEY = "@@driven-filter-settings";
const FILTER_USAGE_HISTORY_KEY = "@@driven-usage-history-filter-settings";
const INVITE_CODE_KEY = "@@driven-invite-code";
const LIST_VIEW_SETTINGS_KEY = "@@driven-list-view-settings";
const MARKETING_KEY = "@@driven-marketing";
const MEMBERSHIP_KEY = "@@driven-membership";
const PERIOD_KEY = "@@driven-subscribe-config-period";
const REFRESH_INTERVAL_ID_KEY = "@@driven-refresh-interval-id";
const SORTING_KEY = "@@driven-sorting-settings-v4";
const THEME_KEY = "@@driven-theme-mode";
const TIER_KEY = "@@driven-subscribe-config-tier";
const VEHICLE_GRID_COLUMNS_VISIBILITY_KEY = "@@vehicle-grid-columns-visibility";
const BACK_KEY = "@@driven-back";

export default class StorageUserPreferenceService
  implements UserPreferenceService
{
  constructor(private storage: Storage) {}

  setAppTheme(AppTheme: AppTheme) {
    this.storage.setItem(THEME_KEY, AppTheme);
  }

  getAppTheme() {
    return (this.storage.getItem(THEME_KEY) ?? DEFAULT_APP_THEME) as AppTheme;
  }

  setDensitySettings(settings: DensitySettings) {
    this.storage.setItem(DENSITY_KEY, JSON.stringify(settings));
  }

  getDensitySettings(): DensitySettings {
    const densitySettings: DensitySettings = JSON.parse(
      this.storage.getItem(DENSITY_KEY) ?? "{}",
    );

    if (densitySettings.vehicle && densitySettings.collection) {
      return {
        [DensityScope.VEHICLE]: validDensities.includes(densitySettings.vehicle)
          ? densitySettings.vehicle
          : DEFAULT_DENSITY_SETTINGS.vehicle,
        [DensityScope.COLLECTION]: validDensities.includes(
          densitySettings.collection,
        )
          ? densitySettings.collection
          : DEFAULT_DENSITY_SETTINGS.collection,
        [DensityScope.LOCATION]: validDensities.includes(
          densitySettings.location,
        )
          ? densitySettings.location
          : DEFAULT_DENSITY_SETTINGS.location,
        [DensityScope.LISTING]: validDensities.includes(densitySettings.listing)
          ? densitySettings.listing
          : DEFAULT_DENSITY_SETTINGS.listing,
        [DensityScope.LOGBOOK]: validDensities.includes(densitySettings.logbook)
          ? densitySettings.logbook
          : DEFAULT_DENSITY_SETTINGS.logbook,
      };
    }

    return DEFAULT_DENSITY_SETTINGS;
  }

  setSortingSettings(sorting: SortingSettings) {
    this.storage.setItem(SORTING_KEY, JSON.stringify(sorting));
  }

  getSortingSettings(): SortingSettings {
    const sortingSettings: SortingSettings = JSON.parse(
      this.storage.getItem(SORTING_KEY) ?? "{}",
    );

    return {
      collection: {
        ...DEFAULT_SORTING_SETTINGS.collection,
        ...sortingSettings.collection,
      },
      [SortingScope.collectors]: {
        ...DEFAULT_SORTING_SETTINGS.collectors,
        ...sortingSettings.collectors,
      },
      [SortingScope.listing]: {
        ...DEFAULT_SORTING_SETTINGS.listing,
        ...sortingSettings.listing,
      },
      [SortingScope.negotiations]: {
        ...DEFAULT_SORTING_SETTINGS.negotiations,
        ...sortingSettings.negotiations,
      },

      [SortingScope.usageHistory]: {
        ...DEFAULT_SORTING_SETTINGS.usageHistory,
        ...sortingSettings.usageHistory,
      },
      [SortingScope.vehicle]: {
        ...DEFAULT_SORTING_SETTINGS.vehicle,
        ...sortingSettings.vehicle,
      },
    };
  }

  setVehicleFilterSettings(filterSettings: VehicleFilterSettings) {
    this.storage.setItem(FILTER_SETTINGS_KEY, JSON.stringify(filterSettings));
  }

  getVehicleFilterSettings() {
    const filterSettings = this.storage.getItem(FILTER_SETTINGS_KEY);
    return filterSettings
      ? JSON.parse(filterSettings)
      : DEFAULT_VEHICLE_FILTER_SETTINGS;
  }

  setListingFilterSettings(filterSettings: ListingFilterSettings) {
    this.storage.setItem(
      FILTER_LISTING_SETTINGS_KEY,
      JSON.stringify(filterSettings),
    );
  }

  getListingFilterSettings() {
    const filterSettings = this.storage.getItem(FILTER_LISTING_SETTINGS_KEY);
    return filterSettings
      ? JSON.parse(filterSettings)
      : DEFAULT_LISTING_FILTER_SETTINGS;
  }

  setLogbookFilterSettings(filterSettings: LogbookFilterSettings) {
    this.storage.setItem(
      FILTER_LOGBOOK_SETTINGS_KEY,
      JSON.stringify(filterSettings),
    );
  }

  getLogbookFilterSettings() {
    const filterSettings = this.storage.getItem(FILTER_LOGBOOK_SETTINGS_KEY);
    return filterSettings
      ? JSON.parse(filterSettings)
      : DEFAULT_LOGBOOK_FILTER_SETTINGS;
  }

  setNegotiationsFilterSettings(filterSettings: NegotiationsFilterSettings) {
    this.storage.setItem(
      FILTER_NEGOTIATIONS_SETTINGS_KEY,
      JSON.stringify(filterSettings),
    );
  }

  getNegotiationsFilterSettings() {
    const filterSettings = this.storage.getItem(
      FILTER_NEGOTIATIONS_SETTINGS_KEY,
    );
    return filterSettings
      ? JSON.parse(filterSettings)
      : DEFAULT_NEGOTIATIONS_FILTER_SETTINGS;
  }

  setServiceRequestsFilterSettings(
    filterSettings: ServiceRequestsFilterSettings,
  ) {
    this.storage.setItem(
      FILTER_SERVICE_REQUESTS_SETTINGS_KEY,
      JSON.stringify(filterSettings),
    );
  }

  getServiceRequestsFilterSettings() {
    const filterSettings = this.storage.getItem(
      FILTER_SERVICE_REQUESTS_SETTINGS_KEY,
    );
    return filterSettings
      ? JSON.parse(filterSettings)
      : DEFAULT_SERVICE_REQUESTS_FILTER_SETTINGS;
  }

  setRemindersFilterSettings(filterSettings: RemindersFilterSettings) {
    this.storage.setItem(
      FILTER_REMINDERS_SETTINGS_KEY,
      JSON.stringify(filterSettings),
    );
  }

  getRemindersFilterSettings() {
    const filterSettings = this.storage.getItem(FILTER_REMINDERS_SETTINGS_KEY);
    return filterSettings
      ? JSON.parse(filterSettings)
      : FILTER_REMINDERS_SETTINGS_KEY;
  }

  setVehicleGridColumnsVisibility(
    columnSettings: VehicleGridColumnsVisibilitySettings,
  ) {
    this.storage.setItem(
      VEHICLE_GRID_COLUMNS_VISIBILITY_KEY,
      JSON.stringify(columnSettings),
    );
  }

  getVehicleGridColumnsVisibility() {
    const columnSettings = this.storage.getItem(
      VEHICLE_GRID_COLUMNS_VISIBILITY_KEY,
    );
    return columnSettings
      ? JSON.parse(columnSettings)
      : DEFAULT_VEHICLE_GRID_COLUMNS_VISIBILITY;
  }

  getRefreshIntervalId() {
    return this.storage.getItem(REFRESH_INTERVAL_ID_KEY);
  }

  trySetRefreshIntervalId(id: number) {
    const currentId = this.getRefreshIntervalId();

    if (currentId) {
      return Number(currentId);
    }

    this.storage.setItem(REFRESH_INTERVAL_ID_KEY, id.toString());

    return id;
  }

  tryUnsetRefreshIntervalId(id: number) {
    const currentId = this.getRefreshIntervalId();

    if (currentId && Number(currentId) === id) {
      this.storage.removeItem(REFRESH_INTERVAL_ID_KEY);
    }
  }

  clearRefreshIntervalId() {
    this.storage.removeItem(REFRESH_INTERVAL_ID_KEY);
  }

  getListViewSettings() {
    return this.storage.getItem(LIST_VIEW_SETTINGS_KEY);
  }

  setListViewSettings(settings?: string) {
    if (settings) {
      this.storage.setItem(LIST_VIEW_SETTINGS_KEY, settings);
    } else {
      this.storage.removeItem(LIST_VIEW_SETTINGS_KEY);
    }
  }

  setMarketing(marketing?: Record<string, string>) {
    if (marketing) {
      this.storage.setItem(MARKETING_KEY, JSON.stringify(marketing));
    } else {
      this.storage.removeItem(MARKETING_KEY);
    }
  }

  getMarketing() {
    const json = this.storage.getItem(MARKETING_KEY);
    return json ? (JSON.parse(json) as Record<string, string>) : null;
  }

  setMembership(membership?: string) {
    if (membership) {
      this.storage.setItem(MEMBERSHIP_KEY, membership);
    } else {
      this.storage.removeItem(MEMBERSHIP_KEY);
    }
  }

  getMembership() {
    return this.storage.getItem(MEMBERSHIP_KEY);
  }

  setCrewInviteCode(code?: string) {
    if (code) {
      this.storage.setItem(INVITE_CODE_KEY, code);
    } else {
      this.storage.removeItem(INVITE_CODE_KEY);
    }
  }

  getCrewInviteCode() {
    return this.storage.getItem(INVITE_CODE_KEY);
  }

  clearSubscribeConfig(): void {
    this.storage.removeItem(TIER_KEY);
    this.storage.removeItem(PERIOD_KEY);
    this.storage.removeItem(BILLING_KEY);
  }

  getSubscribeSummary(): BillingSummary | undefined {
    if (this.hasSubscribeTier() && this.hasSubscribeBilling()) {
      return {
        tier: JSON.parse(this.storage.getItem(TIER_KEY) ?? "null"),
        period: JSON.parse(this.storage.getItem(PERIOD_KEY) ?? "null"),
        billingMethod: JSON.parse(this.storage.getItem(BILLING_KEY) ?? "null"),
      };
    }

    return undefined;
  }

  removeSubscribeBilling(): void {
    this.storage.setItem(BACK_KEY, "true");
    this.storage.removeItem(BILLING_KEY);
  }

  removeSubscribeTier(): void {
    this.storage.removeItem(TIER_KEY);
    this.storage.removeItem(PERIOD_KEY);
  }

  setSubscribeBilling(billingMethod: BillingMethod): void {
    this.storage.removeItem(BACK_KEY);
    this.storage.setItem(BILLING_KEY, JSON.stringify(billingMethod));
  }

  setSubscribeTier(tier: TierInfo, billingPeriod: BillingPeriod): void {
    this.storage.removeItem(BACK_KEY);
    this.storage.setItem(TIER_KEY, JSON.stringify(tier));
    this.storage.setItem(PERIOD_KEY, JSON.stringify(billingPeriod));
  }

  hasSubscribeBilling(): boolean {
    return !!JSON.parse(this.storage.getItem(BILLING_KEY) ?? "null");
  }

  hasSubscribeTier(): boolean {
    return (
      !!JSON.parse(this.storage.getItem(TIER_KEY) ?? "null") &&
      !!JSON.parse(this.storage.getItem(PERIOD_KEY) ?? "null")
    );
  }

  clearSessionSettings() {
    this.storage.removeItem(FILTER_LISTING_SETTINGS_KEY);
    this.storage.removeItem(FILTER_LOGBOOK_SETTINGS_KEY);
    this.storage.removeItem(FILTER_SETTINGS_KEY);
  }

  isBack(): boolean {
    return !!this.storage.getItem(BACK_KEY);
  }

  setDashboardFilterSettings(filterSettings: DashboardFilterSettings) {
    this.storage.setItem(
      FILTER_DASHBOARD_SETTINGS_KEY,
      JSON.stringify(filterSettings),
    );
  }

  getDashboardFilterSettings() {
    const filterSettings = this.storage.getItem(FILTER_DASHBOARD_SETTINGS_KEY);
    return filterSettings
      ? JSON.parse(filterSettings)
      : DEFAULT_DASHBOARD_FILTER_SETTINGS;
  }

  setUsageHistoryFilterSettings(filterSettings: UsageHistoryFilterSettings) {
    this.storage.setItem(
      FILTER_USAGE_HISTORY_KEY,
      JSON.stringify(filterSettings),
    );
  }

  getUsageHistoryFilterSettings() {
    const filterSettings = this.storage.getItem(FILTER_USAGE_HISTORY_KEY);
    return filterSettings
      ? JSON.parse(filterSettings)
      : DEFAULT_USAGE_HISTORY_FILTER_SETTINGS;
  }
}
