import { ApolloClient, gql, Reference } from "@apollo/client";

import { ALLOWED_MIME_TYPES } from "constants/fileRestrictions";
import {
  DOCUMENT_FIELDS,
  MEDIA_FIELDS,
  TAG_FIELDS,
  VAULT_DOCUMENT_FIELDS,
  VEHICLE_CORE_FIELDS,
} from "constants/fragments";
import { Collector } from "typings/Collector";
import { FileOversizedError, MAX_FILE_SIZE } from "typings/FileOversizedError";
import { InvalidMimetypeError } from "typings/InvalidMimetypeError";
import { Media, MediaLinks } from "typings/Media";
import { MediaService } from "typings/MediaService";
import { MimeType } from "typings/MimeType";
import { Tag } from "typings/Tag";

type UploadFileData = {
  uploadFile: Media;
};

const UPLOAD_FILE = gql`
  ${VAULT_DOCUMENT_FIELDS}
  ${VEHICLE_CORE_FIELDS}
  mutation UploadFile(
    $ownerId: ID!
    $isSensitive: Boolean!
    $size: Int!
    $file: Upload!
    $tagsIds: [ID!]
    $vehicleId: ID
    $historyRecordId: ID
    $serviceRequestId: ID
  ) {
    uploadFile(
      ownerId: $ownerId
      isSensitive: $isSensitive
      size: $size
      file: $file
      tagsIds: $tagsIds
      vehicleId: $vehicleId
      historyRecordId: $historyRecordId
      serviceRequestId: $serviceRequestId
    ) {
      ...VaultDocumentFields
      vehicle {
        ...VehicleCoreFields
      }
    }
  }
`;

const REFERENCE_EXTERNAL_MEDIA = gql`
  ${MEDIA_FIELDS}
  mutation ReferenceExternalMedia(
    $url: String!
    $ownerId: ID!
    $isSensitive: Boolean!
    $vehicleId: ID
  ) {
    referenceExternalMedia(
      url: $url
      ownerId: $ownerId
      isSensitive: $isSensitive
      vehicleId: $vehicleId
    ) {
      ...MediaFields
    }
  }
`;

type ResizeImageData = {
  resizeImage: Media;
};

const RESIZE_IMAGE = gql`
  mutation ResizeImage($mediaId: ID!, $crop: CropInput!, $size: SizeInput!) {
    resizeImage(mediaId: $mediaId, crop: $crop, size: $size) {
      id
      url
    }
  }
`;

type GetAvailableTagsData = {
  availableTags: Tag[];
};

const GET_AVAILABLE_TAGS = gql`
  ${TAG_FIELDS}
  query GetAvailableTags {
    availableTags {
      ...TagFields
    }
  }
`;

type GetMediaData = {
  media: Media;
};

const GET_MEDIA = gql`
  ${DOCUMENT_FIELDS}
  query GetMedia($id: ID!) {
    media(id: $id) {
      ...DocumentFields
    }
  }
`;

type CreateTagData = {
  createTag: Tag;
};

const CREATE_TAG = gql`
  ${TAG_FIELDS}
  mutation CreateTag($name: String!) {
    createTag(name: $name) {
      ...TagFields
    }
  }
`;

type RenameTagData = {
  renameTag: Tag;
};

const RENAME_TAG = gql`
  ${TAG_FIELDS}
  mutation RenameTag($id: ID!, $name: String!) {
    renameTag(id: $id, name: $name) {
      ...TagFields
    }
  }
`;

const DELETE_TAG = gql`
  mutation DeleteTag($id: ID!) {
    deleteTag(id: $id) {
      void
    }
  }
`;

export default class ApolloMediaService implements MediaService {
  constructor(private apolloClient: ApolloClient<any>) {}

  async upload(
    file: File,
    ownerId: Collector["id"],
    isSensitive: Media["isSensitive"] = true,
    tagsIds?: Media["tags"],
    links: MediaLinks = {},
  ) {
    if (file.size > MAX_FILE_SIZE) throw new FileOversizedError();

    if (!ALLOWED_MIME_TYPES.includes(file.type as MimeType))
      throw new InvalidMimetypeError();

    const { errors, data } = await this.apolloClient.mutate<UploadFileData>({
      mutation: UPLOAD_FILE,
      variables: {
        file,
        ownerId,
        isSensitive,
        size: file.size,
        tagsIds: tagsIds?.map((tag) => tag.id),
        ...links,
      },
    });

    if (errors) {
      throw errors[0];
    }

    if (data) {
      this.apolloClient.cache.modify<{ vaults: Reference[] }>({
        fields: {
          vaults: (existing, { toReference }) => [
            toReference(data.uploadFile) as Reference,
            ...(existing ?? []),
          ],
        },
      });
    }

    if (data?.uploadFile?.vehicleId) {
      this.apolloClient.cache.modify<{ vaultDocuments: Reference[] }>({
        id: `Vehicle:${data.uploadFile.vehicleId}`,
        fields: {
          vaultDocuments: (existing, { toReference }) => [
            toReference(data.uploadFile) as Reference,
            ...(existing ?? []),
          ],
        },
      });
    }

    if (data?.uploadFile?.serviceRequestId) {
      this.apolloClient.cache.modify<{ documents: Reference[] }>({
        id: `ServiceRequest:${data.uploadFile.serviceRequestId}`,
        fields: {
          documents: (existing, { toReference }) => [
            toReference(data.uploadFile) as Reference,
            ...(existing ?? []),
          ],
        },
      });
    }

    if (data?.uploadFile?.historyRecordId) {
      this.apolloClient.cache.modify<{ documents: Reference[] }>({
        id: `HistoryRecord:${data.uploadFile.historyRecordId}`,
        fields: {
          documents: (existing, { toReference }) => [
            toReference(data.uploadFile) as Reference,
            ...(existing ?? []),
          ],
        },
      });
    }

    return data?.uploadFile;
  }

  async referenceExternalMedia(
    url: string,
    ownerId: Collector["id"],
    isSensitive?: Media["isSensitive"],
    vehicleId?: Media["vehicleId"],
  ) {
    const { errors, data } = await this.apolloClient.mutate({
      mutation: REFERENCE_EXTERNAL_MEDIA,
      variables: { url, ownerId, isSensitive, vehicleId },
    });

    if (errors) {
      throw errors[0];
    }

    return data?.referenceExternalMedia;
  }

  async resize(
    image: Media,
    cropArea: { x: number; y: number; width: number; height: number },
  ) {
    const { errors, data } = await this.apolloClient.mutate<ResizeImageData>({
      mutation: RESIZE_IMAGE,
      variables: {
        mediaId: image.id,
        crop: cropArea,
        size: { width: 128, height: 128 },
      },
    });

    if (errors) {
      throw errors[0];
    }

    return data?.resizeImage;
  }

  async getAvailableTags() {
    const { data, errors } =
      await this.apolloClient.query<GetAvailableTagsData>({
        query: GET_AVAILABLE_TAGS,
      });

    if (errors) {
      throw errors[0];
    }

    return data.availableTags;
  }

  async getMedia(id: Media["id"]) {
    const { data, errors } = await this.apolloClient.query<GetMediaData>({
      query: GET_MEDIA,
      variables: { id },
    });

    if (errors) {
      throw errors[0];
    }

    return data.media;
  }

  async createTag(name: Tag["name"]) {
    const { data, errors } = await this.apolloClient.mutate<CreateTagData>({
      mutation: CREATE_TAG,
      variables: { name },
    });

    if (errors) {
      throw errors[0];
    }

    if (data) {
      this.apolloClient.cache.modify<{ availableTags: Reference[] }>({
        fields: {
          availableTags: (tags, { toReference }) => [
            toReference(data.createTag) as Reference,
            ...tags,
          ],
        },
      });
    }

    return data?.createTag;
  }

  async renameTag(id: Tag["id"], name: Tag["name"]) {
    const { errors } = await this.apolloClient.mutate<RenameTagData>({
      mutation: RENAME_TAG,
      variables: { id, name },
    });

    if (errors) {
      throw errors[0];
    }
  }

  async deleteTag(id: Tag["id"]) {
    const { errors } = await this.apolloClient.mutate({
      mutation: DELETE_TAG,
      variables: { id },
    });

    if (errors) {
      throw errors[0];
    }

    this.apolloClient.cache.evict({ id: `Tag:${id}` });
  }
}
