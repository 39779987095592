import { useEffect, useState } from "react";
import { useResolve } from "react-jpex";

import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { useRefreshMfa } from "hooks/useRefreshMfa";
import { AdminService } from "typings/AdminService";
import { AuthenticationService } from "typings/AuthenticationService";
import { CollectorService } from "typings/CollectorService";
import { Mfa } from "typings/Mfa";

export const useMfaMethods = () => {
  const collectorService = useResolve<CollectorService>("CollectorService");
  const adminService = useResolve<AdminService>("AdminService");
  const authenticationService = useResolve<AuthenticationService>(
    "AuthenticationService",
  );

  const {
    refreshMfa,
    refreshMfaState: { qrCode },
  } = useRefreshMfa();
  const [mfaEnabledMethods, setMfaEnabledMethods] = useState<
    Array<Mfa["method"]>
  >([]);
  const [mfaMethod, setMfaMethod] = useState<Mfa["method"] | null | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  const { enqueueMessage } = useMessaging();

  const handleUseMethod = async (
    method?: Mfa["method"],
    forceRefresh = false,
  ) => {
    setMfaMethod(method);
    if (method) await refreshMfa(method, forceRefresh);
  };

  useEffect(() => {
    const fetchMfaMethods = async () => {
      try {
        setLoading(true);
        const { preferredMfaEnabledMethod: method, ...user } =
          await (authenticationService.isAdmin()
            ? adminService.fetch()
            : collectorService.fetchWithPrivateData(
                authenticationService.getAuthUserId(),
              ));
        setMfaEnabledMethods(user.mfaEnabledMethods ?? []);
        await handleUseMethod(method ?? undefined);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchMfaMethods();
  }, [collectorService, adminService, authenticationService, enqueueMessage]);

  return {
    mfaEnabledMethods,
    mfaMethod,
    handleUseMethod,
    fetchMfaMethodsState: { loading, error },
    qrCode,
  };
};
