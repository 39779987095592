import { useTheme } from "@mui/material/styles";

import { modeByAppTheme } from "constants/palette";
import { useAppTheme } from "contexts/useAppThemeContext";
import { MuiMode } from "typings/AppTheme";
import { IllustrationProps } from "typings/Illustrations";

const FerrariJackedFrontSvg = ({
  width,
  height,
  palette: customPalette,
}: IllustrationProps) => {
  const theme = useTheme();
  const { appTheme } = useAppTheme();

  const palette =
    customPalette ?? modeByAppTheme[appTheme] === MuiMode.LIGHT
      ? [
          theme.palette.grey[200],
          theme.palette.grey[600],
          theme.palette.grey[500],
          theme.palette.grey[300],
          theme.palette.primaryPalette[200],
          theme.palette.primaryPalette[500],
        ]
      : [
          theme.palette.grey[600],
          theme.palette.grey[700],
          theme.palette.grey[600],
          theme.palette.grey[500],
          theme.palette.primaryPalette[400],
          theme.palette.primaryPalette[600],
        ];

  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 618 267"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 20L618 134.888V267L0 173V20Z" fill={palette[0]} />
      <rect
        fill={palette[1]}
        height="10.7435"
        width="162.468"
        x="226.152"
        y="225.616"
      />
      <path
        d="M228.303 192.311H236.911V227.764H229.379L228.303 192.311Z"
        fill={palette[1]}
      />
      <path
        d="M219.695 192.311H227.226L232.606 228.839H225.075L219.695 192.311Z"
        fill={palette[1]}
      />
      <path
        d="M225.076 228.839H232.607L217.544 258.921H211.088L225.076 228.839Z"
        fill={palette[2]}
      />
      <path
        d="M232.607 228.839V234.211L221.847 256.772H218.619L232.607 228.839Z"
        fill={palette[3]}
      />
      <path
        d="M229.916 192.311H227.228L232.608 228.839V212.857L229.916 192.311Z"
        fill={palette[2]}
      />
      <path
        d="M218.627 192.311H221.855V250.326L218.627 256.772V192.311Z"
        fill={palette[2]}
      />
      <path
        d="M211.088 192.311H219.696V255.697H211.088V192.311Z"
        fill={palette[1]}
      />
      <path
        d="M389.695 192.311H381.087V227.764H388.619L389.695 192.311Z"
        fill={palette[1]}
      />
      <path
        d="M398.303 192.311H390.771L385.392 228.839H392.923L398.303 192.311Z"
        fill={palette[1]}
      />
      <path
        d="M392.923 228.839H385.391L400.454 258.921H406.91L392.923 228.839Z"
        fill={palette[2]}
      />
      <path
        d="M385.391 228.839V234.211L396.151 256.772H399.378L385.391 228.839Z"
        fill={palette[3]}
      />
      <path
        d="M388.083 192.311H390.771L385.391 228.839V212.857L388.083 192.311Z"
        fill={palette[2]}
      />
      <path
        d="M399.37 192.311H396.142V250.326L399.37 256.772V192.311Z"
        fill={palette[2]}
      />
      <path
        d="M406.91 192.311H398.302V255.697H406.91V192.311Z"
        fill={palette[1]}
      />
      <rect
        fill={palette[2]}
        height="2.14871"
        width="178.608"
        x="219.696"
        y="252.475"
      />
      <rect
        fill={palette[1]}
        height="10.7435"
        width="195.823"
        x="211.088"
        y="254.622"
      />
      <path
        clipRule="evenodd"
        d="M298.642 0.304434C266.965 1.16226 222.148 2.37594 210.839 18.3084C200.951 37.6862 187.494 71.376 187.054 72.6679C164.537 62.4407 147.76 73.823 141.462 98.5049C139.814 104.964 126.081 176.554 187.054 190.55C214.743 193.613 246.521 193.474 283.032 193.315C291.435 193.279 300.088 193.241 309 193.241C317.913 193.241 326.567 193.279 334.971 193.316C371.481 193.475 403.258 193.613 430.945 190.551C491.919 176.555 478.186 104.965 476.538 98.506C470.24 73.824 453.463 62.4417 430.945 72.6689C430.506 71.3771 417.049 37.6873 407.161 18.3095C395.851 2.37638 351.031 1.16237 319.354 0.304367C315.705 0.205508 312.229 0.111376 309 0C305.769 0.111413 302.293 0.205559 298.642 0.304434ZM308.931 55.8268C275.605 55.8268 211.641 62.8032 199.817 65.4868C192.258 67.2025 212.999 23.2009 218.093 20.4026C228.84 12.3551 243.356 8.59484 308.931 8.59484C374.505 8.59484 389.021 12.3551 399.769 20.4026C404.863 23.2009 425.604 67.2025 418.045 65.4868C406.221 62.8032 342.257 55.8268 308.931 55.8268Z"
        fill={palette[5]}
        fillRule="evenodd"
      />
      <path
        d="M202.533 64.9523C201.448 65.1446 200.536 65.3235 199.818 65.4866C192.259 67.2023 213 23.2008 218.094 20.4025C228.841 12.355 243.357 8.59473 308.932 8.59473C374.506 8.59473 389.022 12.355 399.769 20.4025C404.863 23.2008 425.604 67.2023 418.045 65.4866C417.361 65.3314 416.503 65.1618 415.486 64.9799C416.375 58.4297 399.616 23.6766 398.569 24.7337C397.776 25.5335 398.435 29.848 398.844 32.5288C398.994 33.5082 399.11 34.2697 399.11 34.5619C399.11 35.4275 405.836 54.3745 409.292 63.9994C407.733 63.774 406.029 63.5391 404.2 63.2973L399.38 44.0484L399.874 62.7422C398.672 62.5922 397.431 62.4404 396.153 62.2873C396.153 39.8021 396.146 33.8727 377.862 30.0989C363.308 27.0951 325.857 26.1439 309.001 25.7844C292.144 26.1439 254.694 27.0951 240.14 30.0989C221.86 33.8717 221.849 39.7992 221.849 62.2707C220.571 62.4235 219.33 62.5751 218.128 62.7248L218.621 44.0484L213.806 63.2784C211.947 63.5236 210.217 63.7617 208.634 63.9902C212.059 54.557 218.802 35.7715 218.802 34.9166C218.802 34.6291 218.917 33.88 219.066 32.9164C219.473 30.279 220.128 26.0342 219.34 25.2474C218.292 24.2014 201.437 58.798 202.533 64.9523Z"
        fill={palette[4]}
      />
      <path
        d="M224.756 61.9281C240.589 60.0889 261.118 58.1282 279.778 56.94C261.004 58.0343 240.436 59.9054 224.756 61.9281Z"
        fill={palette[4]}
      />
      <path
        d="M336.641 56.8494C356.211 58.0595 378.05 60.1545 394.457 62.0859C378.2 59.9459 356.319 57.9562 336.641 56.8494Z"
        fill={palette[4]}
      />
      <path
        d="M210.36 141.278C220.68 139.988 270.841 138.59 309.618 138.592C348.333 138.593 398.396 139.988 408.716 141.278C421.616 142.889 420.357 161.724 406.37 167.599C391.026 174.045 309.618 172.971 309.618 172.971C309.618 172.971 228.05 174.045 212.706 167.599C198.719 161.724 197.46 142.889 210.36 141.278Z"
        fill={palette[4]}
      />
      <path
        d="M328.727 103.866C317.716 102.548 298.517 103.317 289.218 103.866C289.218 120.164 302.213 120.982 311.267 120.737C327.271 119.858 329.217 109.482 328.727 103.866Z"
        fill={palette[4]}
      />
      <path
        d="M281.801 104.598C266.825 105.184 252.09 106.398 244.443 107.435C244.443 122.671 257.171 123.826 263.272 123.014C279.411 120.865 281.984 110.702 281.801 104.598Z"
        fill={palette[4]}
      />
      <path
        d="M335.908 104.6C350.885 105.231 365.62 106.54 373.267 107.659C373.267 124.092 360.539 125.337 354.437 124.461C338.298 122.144 335.725 111.183 335.908 104.6Z"
        fill={palette[4]}
      />
      <path
        d="M199.253 112.751C199.253 99.8943 184.28 92.3945 172.525 92.3945C150.602 92.3945 144.192 121.322 151.677 128.822C171.451 150.25 199.253 132.572 199.253 112.751Z"
        fill={palette[4]}
      />
      <path
        d="M418.747 112.751C418.747 99.8943 433.72 92.3945 445.475 92.3945C467.398 92.3945 473.809 121.322 466.324 128.822C446.549 150.25 418.747 132.572 418.747 112.751Z"
        fill={palette[4]}
      />
    </svg>
  );
};

export default FerrariJackedFrontSvg;
