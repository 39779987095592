import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import { TextFieldControlled } from "components/inputs/TextField/TextFieldControlled";

export const ServiceForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <TextFieldControlled
          fullWidth
          required
          data-testid="service-title-input"
          label={t("title")}
          name="title"
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldControlled
          clearable
          fullWidth
          multiline
          data-testid="service-description-input"
          label={t("description")}
          minRows={2}
          name="description"
        />
      </Grid>
    </>
  );
};
