import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import FerrariJackedFront from "assets/FerrariJackedFront";
import { reload } from "utils/window";

const ErrorPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Container
      maxWidth="md"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100%",
      }}
    >
      <Stack component={Paper} sx={{ width: "100%", p: 4, gap: 4 }}>
        <Typography sx={{ fontWeight: 400 }} variant="h1">
          {t("error.boundary.title")}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <FerrariJackedFront height="200" width="400" />
        </Box>

        <Typography variant="body1">
          {t("error.boundary.description")}
        </Typography>

        <Stack
          direction="row"
          sx={{
            gap: 4,
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            sx={{ whiteSpace: "nowrap", minWidth: "fit-content" }}
            type="button"
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            {t("goBack")}
          </Button>

          <Button
            color="primary"
            sx={{ whiteSpace: "nowrap", minWidth: "fit-content" }}
            type="button"
            variant="outlined"
            onClick={() => reload({ force: true })}
          >
            {t("reload")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ErrorPage;
