import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { DialogCloseButton } from "components/DialogCloseButton/DialogCloseButton";

type DialogHeaderProps = {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  onClose?: () => void;
};

export const DialogHeader = ({
  title = "",
  subtitle,
  onClose,
}: DialogHeaderProps) => {
  return (
    <Stack
      direction="column"
      gap={0.5}
      justifyContent="center"
      sx={{ pl: 3, pr: 1.5, py: 1.5, backgroundColor: "background.default" }}
    >
      <Stack direction="row" gap="2">
        <Typography alignSelf="center" variant="h5">
          {title}
        </Typography>
        {!!onClose && (
          <Box sx={{ ml: "auto" }}>
            <DialogCloseButton onClose={onClose} />
          </Box>
        )}
      </Stack>
      {!!subtitle && <Typography variant="body2">{subtitle}</Typography>}
    </Stack>
  );
};
