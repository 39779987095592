const gtmId = import.meta.env.REACT_APP_GOOGLE_TAG_MANAGER ?? "";

const gtmSrc = "https://hermes.driven.world";

const dataLayerName = "dataLayer";

const noScript = () => {
  const noScriptEl = document.createElement("noscript");
  noScriptEl.innerHTML = `
      <iframe src="${gtmSrc}/ns.html?id=${gtmId}&gtm_cookies_win=x"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
  return noScriptEl;
};

const script = () => {
  const scriptEl = document.createElement("script");
  scriptEl.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  '${gtmSrc}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','${dataLayerName}','${gtmId}');`;
  return scriptEl;
};

export const initGtm = () => {
  if (document) {
    document.head.insertBefore(script(), document.head.childNodes[0]);
    document.body.insertBefore(noScript(), document.body.childNodes[0]);
  }
};
