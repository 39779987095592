import { browserTracingIntegration, init } from "@sentry/react";
import { withoutGraphQLFetch } from "apollo-link-sentry";

const ignoreTransactions = [
  "version.txt",
  "hermes.driven.world",
  "mouseflow.com",
  "iubenda.com",
  "taboola.com",
];

const shouldCreateSpanForRequest = (url: string) => {
  const isIgnored = ignoreTransactions.some((i) => url.indexOf(i) !== -1);

  return !isIgnored;
};

type Env = "development" | "production" | "staging";

const tracesSampleRateByEnv: Record<Env, number> = {
  development: 1,
  production: 0.5,
  staging: 1,
};

const tracesSampleRate = tracesSampleRateByEnv[import.meta.env.NODE_ENV as Env];

export function initSentry() {
  if (import.meta.env.REACT_APP_SENTRY_ENV)
    init({
      dsn: "https://0b1131dc4f9f43d4902c42296e946193@o999407.ingest.sentry.io/5958343",
      beforeBreadcrumb: withoutGraphQLFetch((breadcrumb) => {
        if (
          breadcrumb.category === "console" &&
          breadcrumb.level === "warning"
        ) {
          return null;
        } else {
          return breadcrumb;
        }
      }),
      integrations: [
        browserTracingIntegration({
          shouldCreateSpanForRequest,
          traceXHR: false,
        }),
      ],
      tracesSampleRate,
      environment: import.meta.env.REACT_APP_SENTRY_ENV,
      ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      release: import.meta.env.REACT_APP_VERSION,
      ignoreTransactions,
      tracePropagationTargets: [
        "localhost:3000",
        /^\//,
        /api\.driven\.world\//,
        /api\.staging\.driven\.world\//,
      ],
    });
}
