import { ApolloClient, gql } from "@apollo/client";
import { setUser } from "@sentry/react";
import { Buffer } from "buffer";

import {
  COLLECTOR_PROFILE_FIELDS,
  MEMBERSHIP_FRAGMENT,
  QUOTA_FRAGMENT,
  SUBSCRIPTION_FRAGMENT,
  TIER_FRAGMENT,
  USER_FIELDS,
} from "constants/fragments";
import { Collector } from "typings/Collector";
import { CollectorService } from "typings/CollectorService";
import { Mfa } from "typings/Mfa";
import { filterEmptyProperties } from "utils/filterEmptyProperties";

export const FETCH_DETAILS = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  ${MEMBERSHIP_FRAGMENT}
  ${USER_FIELDS}
  ${QUOTA_FRAGMENT}
  ${TIER_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
  query FetchWithPrivateData($id: ID!) {
    collector(id: $id) {
      ...UserFields
      ...CollectorProfileFields
      confirmed
      managerOfCrews {
        ...MembershipFragment
      }
      memberOfCrews {
        ...MembershipFragment
      }
      organizationsIds
      concierges {
        ...CollectorProfileFields
      }
      quota {
        ...QuotaFragment
      }
      tier {
        ...TierFragment
      }
      tierExpirationDate
      paywallDisabled
      externalId
      subscriptions {
        ...SubscriptionFragment
      }
      enabledExperimentalFeatures
    }
  }
`;

const RESEND_CONFIRM_EMAIL = gql`
  mutation ResendConfirmEmail($email: String) {
    resendConfirmEmail(email: $email) {
      void
    }
  }
`;

const SET_PROFILE_PICTURE = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  mutation SetProfilePicture($profilePictureId: ID!) {
    setProfilePicture(profilePictureId: $profilePictureId) {
      ...CollectorProfileFields
    }
  }
`;

const SET_USER_MARKETING = gql`
  mutation SetUserMarketing($marketing: String) {
    setUserMarketing(marketing: $marketing) {
      id
      marketing
    }
  }
`;

const SET_USER_MEMBERSHIP = gql`
  mutation SetUserMembership($membership: String) {
    setUserMembership(membership: $membership) {
      id
      membership
    }
  }
`;

export const SET_PROFILE_DATA = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  mutation SetProfileData(
    $username: String!
    $bio: String
    $socialLinks: String
  ) {
    setUserData(username: $username, bio: $bio, socialLinks: $socialLinks) {
      ...CollectorProfileFields
    }
  }
`;

const FETCH_COLLECTOR = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  query FetchCollector($id: ID!) {
    collector(id: $id) {
      ...CollectorProfileFields
    }
  }
`;

const SELECT_MFA = gql`
  mutation SelectMfa($mfaMethod: String!, $credentialId: String) {
    selectMfa(mfaMethod: $mfaMethod, credentialId: $credentialId) {
      isEnabled
      method
      qrCode
      recipient
    }
  }
`;

const ENABLE_MFA = gql`
  mutation EnableMfa(
    $mfaMethod: String!
    $mfaCode: String!
    $credentialId: String
  ) {
    enableMfa(
      mfaMethod: $mfaMethod
      mfaCode: $mfaCode
      credentialId: $credentialId
    ) {
      isEnabled
      method
      ownerId
      qrCode
      recipient
      credentials
    }
  }
`;

const DISABLE_MFA = gql`
  mutation DisableMfa(
    $mfaMethod: String!
    $mfaCode: String!
    $credentialId: String
  ) {
    disableMfa(
      mfaMethod: $mfaMethod
      mfaCode: $mfaCode
      credentialId: $credentialId
    ) {
      void
    }
  }
`;

export default class ApolloCollectorService implements CollectorService {
  constructor(private apolloClient: ApolloClient<any>) {}

  async fetch(id: Collector["id"]): Promise<Collector> {
    const { data, errors } = await this.apolloClient.query<{
      collector: Collector;
    }>({
      query: FETCH_COLLECTOR,
      variables: { id },
    });

    if (errors) {
      throw errors[0];
    }

    return data.collector;
  }

  async resendConfirmEmail(): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: RESEND_CONFIRM_EMAIL,
    });

    if (errors) {
      throw errors[0];
    }
  }

  async fetchWithPrivateData(id: Collector["id"]): Promise<Collector> {
    const { data, error } = await this.apolloClient.query<{
      collector: Collector;
    }>({
      query: FETCH_DETAILS,
      variables: { id },
    });

    if (error) {
      throw error;
    }

    setUser({ id: data.collector.id });

    return data.collector;
  }

  async setMarketing(marketing: Collector["marketing"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: SET_USER_MARKETING,
      variables: {
        marketing: Buffer.from(
          JSON.stringify(filterEmptyProperties(marketing ?? {})),
        ).toString("base64"),
      },
    });

    if (errors) {
      throw errors[0];
    }
  }

  async setMembership(membership: Collector["membership"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: SET_USER_MEMBERSHIP,
      variables: { membership },
    });

    if (errors) {
      throw errors[0];
    }
  }

  async setProfilePicture(
    thumbnail: Collector["profilePicture"],
  ): Promise<void> {
    if (thumbnail?.id) {
      const { errors } = await this.apolloClient.mutate({
        mutation: SET_PROFILE_PICTURE,
        variables: { profilePictureId: thumbnail.id },
      });

      if (errors) {
        throw errors[0];
      }
    }
  }

  async selectMfa(method: Mfa["method"], credentialId?: string): Promise<Mfa> {
    const { data, errors } = await this.apolloClient.mutate({
      mutation: SELECT_MFA,
      variables: {
        mfaMethod: method,
        credentialId,
      },
    });

    if (errors) {
      throw errors[0];
    }

    return data.selectMfa;
  }

  async enableMfa(
    method: Mfa["method"],
    code: Mfa["code"],
    credentialId?: string,
  ): Promise<void> {
    const { errors, data } = await this.apolloClient.mutate<{ enableMfa: Mfa }>(
      {
        mutation: ENABLE_MFA,
        variables: {
          mfaMethod: method,
          mfaCode: code,
          credentialId,
        },
      },
    );

    if (errors) {
      throw errors[0];
    }

    if (data?.enableMfa) {
      this.apolloClient.cache.modify<{
        mfaEnabledMethods: Array<Mfa["method"]>;
        isMfaEnabled: boolean;
        mfas: Mfa[];
      }>({
        id: `Collector:${data.enableMfa.ownerId}`,
        fields: {
          mfaEnabledMethods: (existing) =>
            [
              data.enableMfa.method,
              ...(existing as unknown as Mfa["method"]),
            ] as Array<Mfa["method"]>,
          isMfaEnabled: () => data.enableMfa.isEnabled,
          mfas: (existing) =>
            ((existing as Mfa[]) ?? [])
              .filter((mfa: Mfa) => mfa.method !== data.enableMfa.method)
              .concat(data.enableMfa),
        },
      });
    }
  }

  async disableMfa(
    collectorId: Collector["id"],
    mfaMethod: Mfa["method"],
    mfaCode: Mfa["code"],
    credentialId?: string,
  ): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: DISABLE_MFA,
      variables: { mfaMethod, mfaCode, credentialId },
    });

    if (errors) {
      throw errors[0];
    }

    this.apolloClient.cache.modify({
      id: `Collector:${collectorId}`,
      fields(fieldValue, details) {
        return details.DELETE;
      },
    });
  }

  async refreshMfa(collectorId: Collector["id"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: DISABLE_MFA,
      variables: {},
    });

    if (errors) {
      throw errors[0];
    }

    this.apolloClient.cache.modify({
      id: `Collector:${collectorId}`,
      fields: {
        mfaEnabledMethod: () => null,
        isMfaEnabled: () => false,
      },
    });
  }

  async changeEmail(email: Collector["email"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: RESEND_CONFIRM_EMAIL,
      variables: { email },
    });

    if (errors) {
      throw errors[0];
    }
  }
}
