import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ErrorBoundary } from "@sentry/react";
import { AppMeta } from "AppMeta";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { VersionChecker } from "components/VersionChecker/VersionChecker";
import { MessagingContextProvider } from "contexts/Messaging/useMessagingContext";
import { ConfirmProvider } from "contexts/useConfirmContext";
import { SpecsFilterContextProvider } from "contexts/useSpecsFilterContext";
import { useEventSource } from "hooks/useEventSource";
import { useMuiTheme } from "hooks/useMuiTheme";
import { AppRoutes } from "pages/AppRoutes";
import ServiceProvider from "services/ServiceProvider";

export default function App() {
  const { muiTheme } = useMuiTheme();

  useEventSource();

  return (
    <HelmetProvider>
      <AppMeta />

      <ThemeProvider theme={muiTheme}>
        <CssBaseline enableColorScheme />

        <MessagingContextProvider>
          <SpecsFilterContextProvider>
            <ApolloProvider client={ServiceProvider.getApolloClient()}>
              <ConfirmProvider>
                <VersionChecker />
                <ErrorBoundary>
                  <SuspenseWrapper>
                    <AppRoutes />
                  </SuspenseWrapper>
                </ErrorBoundary>
              </ConfirmProvider>
            </ApolloProvider>
          </SpecsFilterContextProvider>
        </MessagingContextProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}
