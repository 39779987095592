import { WHITE } from "constants/layout";
import { AppTheme, MuiMode } from "typings/AppTheme";
import { Organizations } from "typings/Organization";

export const DARK_ERROR = {
  100: "hsl(0, 100%, 93%)",
  200: "hsl(0, 100%, 85%)",
  300: "hsl(0, 100%, 78%)",
  400: "hsl(0, 100%, 71%)",
  500: "hsl(0, 100%, 64%)",
  600: "hsl(0, 59%, 51%)",
  700: "hsl(0, 56%, 38%)",
  800: "hsl(0, 57%, 25%)",
  900: "hsl(0, 57%, 13%)",
};

export const DARK_GREY = {
  100: "hsl(240, 12%, 92%)",
  200: "hsl(235, 12%, 80%)",
  300: "hsl(231, 13%, 69%)",
  400: "hsl(234, 12%, 55%)",
  500: "hsl(233, 16%, 44%)",
  600: "hsl(233, 15%, 33%)",
  700: "hsl(234, 15%, 24%)",
  800: "hsl(235, 15%, 15%)",
  850: "hsl(235, 13%, 12%)",
  900: "hsl(233, 14%, 11%)",
};

export const DARK_INFO = {
  100: "hsl(190, 96%, 91%)",
  200: "hsl(194, 98%, 82%)",
  300: "hsl(197, 97%, 74%)",
  400: "hsl(201, 95%, 66%)",
  500: "hsl(204, 95%, 55%)",
  600: "hsl(207, 80%, 47%)",
  700: "hsl(211, 83%, 38%)",
  800: "hsl(214, 87%, 30%)",
  900: "hsl(218, 90%, 25%)",
};

export const DARK_PRIMARY = {
  100: "hsl(26, 94%, 93%)",
  200: "hsl(22, 97%, 87%)",
  300: "hsl(17, 96%, 80%)",
  400: "hsl(13, 95%, 75%)",
  500: "hsl(16, 76%, 51%)",
  600: "hsl(3, 65%, 55%)",
  700: "hsl(358, 60%, 44%)",
  800: "hsl(354, 67%, 34%)",
  900: "hsl(349, 75%, 27%)",
};

export const DARK_SUCCESS = {
  100: "hsl(106, 93%, 95%)",
  200: "hsl(111, 92%, 90%)",
  300: "hsl(117, 86%, 84%)",
  400: "hsl(122, 80%, 78%)",
  500: "hsl(128, 76%, 71%)",
  600: "hsl(134, 52%, 57%)",
  700: "hsl(139, 47%, 45%)",
  800: "hsl(144, 56%, 35%)",
  900: "hsl(150, 66%, 27%)",
};

export const DARK_WARNING = {
  100: "hsl(53, 96%, 90%)",
  200: "hsl(50, 98%, 80%)",
  300: "hsl(49, 99%, 71%)",
  400: "hsl(47, 98%, 63%)",
  500: "hsl(46, 98%, 51%)",
  600: "hsl(44, 94%, 44%)",
  700: "hsl(43, 95%, 36%)",
  800: "hsl(42, 96%, 29%)",
  900: "hsl(40, 98%, 24%)",
};

export const LIGHT_ERROR = {
  100: "hsl(25, 96%, 91%)",
  200: "hsl(20, 98%, 83%)",
  300: "hsl(15, 97%, 74%)",
  400: "hsl(11, 95%, 67%)",
  500: "hsl(6, 95%, 56%)",
  600: "hsl(1, 77%, 47%)",
  700: "hsl(356, 81%, 39%)",
  800: "hsl(351, 85%, 31%)",
  900: "hsl(347, 89%, 25%)",
};

export const LIGHT_INFO = {
  100: "hsl(188, 96%, 90%)",
  200: "hsl(191, 96%, 79%)",
  300: "hsl(194, 93%, 68%)",
  400: "hsl(198, 89%, 60%)",
  500: "hsl(207, 84%, 49%)",
  600: "hsl(206, 100%, 40%)",
  700: "hsl(209, 100%, 33%)",
  800: "hsl(213, 100%, 27%)",
  900: "hsl(217, 100%, 22%)",
};

export const LIGHT_GREY = {
  100: "hsla(0, 0%, 97%, 1)",
  200: "hsla(0, 0%, 93%, 1)",
  300: "hsla(0, 0%, 87%, 1)",
  400: "hsla(0, 0%, 79%, 1)",
  500: "hsla(0, 0%, 66%, 1)",
  600: "hsla(0, 0%, 53%, 1)",
  700: "hsla(0, 0%, 38%, 1)",
  800: "hsla(0, 0%, 33%, 1)",
  900: "hsla(0, 0%, 20%, 1)",
};

export const LIGHT_PRIMARY = {
  100: "hsl(31, 100%, 91%)",
  200: "hsl(27, 100%, 83%)",
  300: "hsl(23, 100%, 74%)",
  400: "hsl(18, 100%, 68%)",
  500: "hsl(14, 100%, 57%)",
  600: "hsl(10, 79%, 48%)",
  700: "hsl(5, 82%, 39%)",
  800: "hsl(1, 86%, 31%)",
  900: "hsl(356, 91%, 25%)",
};

export const LIGHT_SUCCESS = {
  100: "hsl(88, 83%, 91%)",
  200: "hsl(93, 85%, 82%)",
  300: "hsl(97, 75%, 70%)",
  400: "hsl(101, 63%, 60%)",
  500: "hsl(106, 66%, 46%)",
  600: "hsl(110, 70%, 38%)",
  700: "hsl(114, 75%, 31%)",
  800: "hsl(119, 80%, 24%)",
  900: "hsl(124, 86 %, 19 %)",
};

export const LIGHT_WARNING = {
  100: "hsl(49, 96%, 90%)",
  200: "hsl(48, 96%, 79%)",
  300: "hsl(46, 95%, 69%)",
  400: "hsl(44, 92%, 60%)",
  500: "hsl(42, 100%, 47%)",
  600: "hsl(40, 100%, 41%)",
  700: "hsl(38, 100%, 34%)",
  800: "hsl(36, 100%, 27%)",
  900: "hsl(34, 100%, 23%)",
};

export const LIGHT_AI = {
  100: "hsl(283, 100%, 95%)",
  200: "hsl(284, 100%, 83%)",
  300: "hsl(284, 100%, 72%)",
  400: "hsl(284, 100%, 61%)",
  500: "hsl(284, 100%, 50%)",
  600: "hsl(284, 100%, 39%)",
  700: "hsl(284, 100%, 28%)",
  800: "hsl(284, 100%, 17%)",
  900: "hsl(285, 100%, 5%)",
};

export const DARK_AI = {
  100: "hsl(285, 100%, 5%)",
  200: "hsl(284, 100%, 17%)",
  300: "hsl(284, 100%, 28%)",
  400: "hsl(284, 100%, 39%)",
  500: "hsl(284, 100%, 50%)",
  600: "hsl(284, 100%, 61%)",
  700: "hsl(284, 100%, 72%)",
  800: "hsl(284, 100%, 83%)",
  900: "hsl(283, 100%, 95%)",
};

export const BRANDS: Record<Organizations, { main: string }> = {
  [Organizations.ACME]: { main: "	hsla(281, 94%, 31%, 1)" },
  [Organizations.ASCARI]: { main: "hsla(184, 100%, 21%, 1)" },
  [Organizations.BI]: { main: "hsla(54, 100%, 40%, 1)" },
  [Organizations.CURATED]: { main: "hsla(0, 0%, 0%, 1)" },
  [Organizations.DRIVEN]: { main: "hsla(14, 100%, 57%, 1)" },
  [Organizations.ILR]: { main: "hsla(44, 84%, 57%, 1)" },
  [Organizations.KESSEL]: { main: "hsla(0, 0%, 99%, 1)" },
};

export const modeByAppTheme: Record<AppTheme, MuiMode> = {
  [AppTheme.ACME]: MuiMode.LIGHT,
  [AppTheme.ASCARI_LIGHT]: MuiMode.LIGHT,
  [AppTheme.BI_DARK]: MuiMode.DARK,
  [AppTheme.CURATED_LIGHT]: MuiMode.LIGHT,
  [AppTheme.DRIVEN_DARK]: MuiMode.DARK,
  [AppTheme.DRIVEN_LIGHT]: MuiMode.LIGHT,
  [AppTheme.ILR_DARK]: MuiMode.DARK,
  [AppTheme.KESSEL_DARK]: MuiMode.DARK,
};

export const DRIVEN_LIGHT_PALETTE = {
  ...BRANDS,
  mode: modeByAppTheme[AppTheme.DRIVEN_LIGHT],
  background: {
    cardEmptyHeader: LIGHT_GREY[200],
    cardLip: LIGHT_GREY[100],
    chatBubble: {
      self: LIGHT_INFO[500],
      other: LIGHT_GREY[100],
    },
    dark: DARK_GREY[800],
    default: WHITE,
    dividerPattern: "hsla(14, 84%, 67%, 1)",
    light: WHITE,
    nav: WHITE,
    paper: WHITE,
    paperHover: WHITE,
    reminderDateCircle: LIGHT_GREY[200],
    timelineConnector: LIGHT_GREY[200],
  },
  icon: {
    verificationStatus: DARK_GREY[600],
    highlightChip: {
      backgroundColor: "hsla(49, 96%, 90%, 1)",
      color: "hsla(40, 100%, 41%, 1)",
    },
  },
  border: {
    card: { main: "hsla(0, 0%, 85%, 1)", hover: "hsla(0, 0%, 70%, 1)" },
    dropZone: "hsla(0, 0%, 0%, 0.23)",
  },
  shadow: {
    flag: "0px 0px 2px 0px hsla(0, 0%, 0%, 0.23)",
    default: "0px 0px 20px 0px hsla(235, 15%, 15%, 0.066)",
    hover: "0px 0px 50px 0px hsla(235, 15%, 15%, 0.125)",
  },
  dark: { main: DARK_GREY[800] },
  error: { main: LIGHT_ERROR[500] },
  grey: LIGHT_GREY,
  info: { main: LIGHT_INFO[500] },
  light: { main: WHITE },
  ai: {
    contrastText: WHITE,
    dark: LIGHT_AI[600],
    light: LIGHT_AI[200],
    main: LIGHT_AI[400],
  },
  primary: {
    contrastText: "hsla(0, 0%, 100%, 0.95)",
    main: "hsla(14, 100%, 57%, 1)",
    dark: "hsla(14, 95%, 50%, 1)",
  },
  primaryPalette: LIGHT_PRIMARY,
  secondary: {
    contrastText: "hsla(0, 0%, 100%, 0.95)",
    main: "hsla(243, 20.83%, 18.82%, 1)",
  },
  success: { main: LIGHT_SUCCESS[600] },
  text: {
    ai: LIGHT_AI[600],
    dark: DARK_GREY[900],
    disabled: "hsla(236, 60%, 2%, 0.37)",
    light: "hsla(0, 0%, 100%, 0.87)",
    primary: DARK_GREY[900],
    secondary: "hsla(236, 60%, 5%, 0.67)",
  },
  warning: { main: LIGHT_WARNING[500] },
};

export const ACME_PALETTE = {
  ...DRIVEN_LIGHT_PALETTE,
  mode: modeByAppTheme[AppTheme.ACME],
  primary: {
    contrastText: "hsla(0, 0%, 100%, 0.95)",
    main: "hsla(281, 94%, 31%, 1)",
    dark: "hsla(281, 90%, 25%, 1)",
  },
  secondary: {
    contrastText: "hsla(0, 0%, 100%, 0.95)",
    main: "hsla(33, 100%, 49%, 1)",
  },
  shadow: {
    flag: "0px 0px 2px 0px hsla(0, 0%, 0%, 0.23)",
    default: "0px 0px 20px 0px hsla(281, 94%, 31%, 0.05)",
    hover: "0px 0px 50px 0px hsla(281, 94%, 31%, 0.25)",
  },
};

export const ASCARI_PALETTE = {
  ...DRIVEN_LIGHT_PALETTE,
  mode: modeByAppTheme[AppTheme.ASCARI_LIGHT],
  primary: {
    contrastText: "hsla(0, 0%, 100%, 0.95)",
    main: "hsla(183.93, 100%, 20.98%, 1)",
    dark: "hsla(183.93, 95%, 16%, 1)",
  },
  secondary: {
    contrastText: "hsla(0, 0%, 100%, 0.95)",
    main: "hsla(33, 100%, 49%, 1)",
  },
  shadow: {
    flag: "0px 0px 2px 0px hsla(0, 0%, 0%, 0.23)",
    default: "0px 0px 20px 0px hsla(183.93, 100%, 20.98%, 0.05)",
    hover: "0px 0px 50px 0px hsla(183.93, 100%, 20.98%, 0.25)",
  },
};

export const CURATED_PALETTE = {
  ...DRIVEN_LIGHT_PALETTE,
  mode: modeByAppTheme[AppTheme.CURATED_LIGHT],
  primary: {
    contrastText: "hsla(0, 0%, 100%, 0.95)",
    main: "hsla(144, 87%, 28%, 1)",
    dark: "hsla(144, 87%, 17%, 1)",
  },
  secondary: {
    contrastText: "hsla(0, 0%, 100%, 0.95)",
    main: "hsla(33, 100%, 49%, 1)",
  },
  shadow: {
    flag: "0px 0px 2px 0px hsla(0, 0%, 0%, 0.23)",
    default: "0px 0px 20px 0px hsla(281, 94%, 31%, 0.05)",
    hover: "0px 0px 50px 0px hsla(144, 87%, 17%, 0.23)",
  },
};

export const DRIVEN_DARK_PALETTE = {
  ...BRANDS,
  mode: modeByAppTheme[AppTheme.DRIVEN_DARK],
  background: {
    cardEmptyHeader: DARK_GREY[700],
    cardLip: DARK_GREY[800],
    chatBubble: {
      self: DARK_INFO[700],
      other: DARK_GREY[700],
    },
    dark: DARK_GREY[800],
    default: DARK_GREY[900],
    dividerPattern: "hsla(14, 84%, 67%, 1)",
    light: "hsla(0, 0%, 94%, 1)",
    nav: DARK_GREY[850],
    paper: DARK_GREY[850],
    paperHover: DARK_GREY[800],
    reminderDateCircle: DARK_GREY[700],
    timelineConnector: DARK_GREY[700],
  },
  border: {
    card: {
      hover: "hsla(0, 0%, 30%, 1)",
      main: "hsla(0, 0%, 20%, 1)",
    },
    dropZone: "hsla(0, 0%, 100%, 0.23)",
  },
  shadow: {
    flag: "0px 0px 2px 0px hsla(0, 0%, 100%, 0.25)",
    default: "0px 0px 20px 0px hsla(235, 45%, 45%, 0.1)",
    hover: "0px 0px 50px 0px hsla(235, 45%, 45%, 0.2)",
  },
  icon: {
    verificationStatus: DARK_GREY[300],
    highlightChip: {
      color: "hsla(49, 96%, 90%, 1)",
      backgroundColor: "hsla(40, 100%, 41%, 1)",
    },
  },
  dark: { main: DARK_GREY[800] },
  error: { main: DARK_ERROR[500] },
  grey: DARK_GREY,
  iconContrast: "hsla(5, 29%, 39%, 1)",
  info: { main: DARK_INFO[600] },
  light: { main: WHITE },
  ai: {
    contrastText: "hsla(0, 0%, 100%, 0.87)",
    dark: DARK_AI[200],
    light: DARK_AI[500],
    main: DARK_AI[400],
  },
  primary: {
    contrastText: "hsla(216, 25%, 12%, 0.95)",
    dark: "hsla(17, 100%, 50%, 1)",
    light: "hsla(14, 95%, 77%, 1)",
    main: "hsla(8, 95%, 67%, 1)",
  },
  primaryPalette: DARK_PRIMARY,
  secondary: {
    contrastText: "hsla(216, 25%, 12%, 0.95)",
    main: "hsla(0, 0%, 100%, 0.87)",
  },
  success: { main: DARK_SUCCESS[700], contrastText: "hsla(0, 0%, 100%, 0.87)" },
  text: {
    ai: DARK_AI[700],
    dark: "hsla(236, 50%, 13%, 1)",
    light: "hsla(0, 0%, 100%, 0.87)",
    primary: "hsla(0, 0%, 100%, 0.87)",
    secondary: "hsla(0, 0%, 100%, 0.70)",
  },
  warning: { main: DARK_WARNING[600] },
};

export const BI_PALETTE = {
  ...DRIVEN_DARK_PALETTE,
  mode: modeByAppTheme[AppTheme.BI_DARK],
  background: {
    ...DRIVEN_DARK_PALETTE.background,
    backdrop: "hsla(54, 100%, 4.89%, 0.75)",
    cardEmptyHeader: "hsla(0, 0%, 10%, 1)",
    cardLip: "hsla(0, 0%, 7%, 1)",
    dark: "hsla(0, 0%, 0%, 1)",
    default: "hsla(0, 0%, 0%, 1)",
    nav: "hsla(0, 0%, 0%, 1)",
    paper: "hsla(0, 0%, 5%, 1)",
  },
  primary: {
    contrastText: "hsla(216, 25%, 12%, 0.95)",
    dark: "hsla(54, 100%, 33%, 1)",
    light: "hsla(54, 100%, 66%, 1)",
    main: "hsla(54, 100%, 40%, 1)",
  },
  shadow: {
    flag: "0px 0px 2px 0px hsla(0, 0%, 100%, 0.25)",
    default: "0px 0px 20px 0px hsla(54, 100%, 50%, 0.1)",
    hover: "0px 0px 50px 0px hsla(54, 100%, 50%, 0.2)",
  },
};

export const ILR_PALETTE = {
  ...DRIVEN_DARK_PALETTE,
  mode: modeByAppTheme[AppTheme.BI_DARK],
  background: {
    ...DRIVEN_DARK_PALETTE.background,
    backdrop: "hsla(54, 100%, 4.89%, 0.75)",
    cardEmptyHeader: "hsla(0, 0%, 19%, 1)",
    cardLip: "hsla(0, 0%, 16%, 1)",
    dark: "hsla(0, 0%, 9%, 1)",
    default: "hsla(0, 0%, 9%, 1)",
    nav: "hsla(0, 0%, 9%, 1)",
    paper: "hsla(0, 0%, 14%, 1)",
  },
  primary: {
    contrastText: "hsla(216, 25%, 12%, 0.95)",
    dark: "hsla(44, 84%, 45%, 1)",
    light: "hsla(44, 84%, 64%, 1)",
    main: "hsla(44, 84%, 57%, 1)",
  },
  shadow: {
    flag: "0px 0px 2px 0px hsla(0, 0%, 100%, 0.25)",
    default: "0px 0px 20px 0px hsla(54, 100%, 50%, 0.05)",
    hover: "0px 0px 50px 0px hsla(54, 100%, 50%, 0.1)",
  },
};

export const KESSEL_PALETTE = {
  ...BI_PALETTE,
  mode: modeByAppTheme[AppTheme.KESSEL_DARK],
  primary: {
    contrastText: "hsla(216, 25%, 12%, 0.95)",
    dark: "hsla(0, 0%, 90%, 1)",
    light: "hsla(0, 0%, 100%, 1)",
    main: "hsla(0, 0%, 99%, 1)",
  },
  shadow: {
    flag: "0px 0px 2px 0px hsla(0, 100%, 90%, 0.25)",
    default: "0px 0px 20px 0px hsla(0, 0%, 90%, 0.1)",
    hover: "0px 0px 50px 0px hsla(0, 0%, 90%, 0.2)",
  },
};
