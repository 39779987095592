// forked from https://github.com/drac94/react-auth-code-input/blob/master/src/index.tsx
import {
  ChangeEvent,
  ClipboardEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import { TextField } from "components/inputs/TextField/TextField";

const allowedCharactersValues = ["alpha", "numeric", "alphanumeric"] as const;

type InputMode = "text" | "numeric";

type InputType = "text" | "tel" | "password";

type InputProps = {
  type: InputType;
  inputMode: InputMode;
  pattern: string;
  min?: string;
  max?: string;
};

const propsMap: { [key: string]: InputProps } = {
  alpha: {
    type: "text",
    inputMode: "text",
    pattern: "[a-zA-Z]{1}",
  },

  alphanumeric: {
    type: "text",
    inputMode: "text",
    pattern: "[a-zA-Z0-9]{1}",
  },

  numeric: {
    type: "tel",
    inputMode: "numeric",
    pattern: "[0-9]{1}",
    min: "0",
    max: "9",
  },
};

export type OtpRef = {
  focus: () => void;
  clear: () => void;
};

export type OtpFieldProps = {
  allowedCharacters?: (typeof allowedCharactersValues)[number];
  ariaLabel?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  isPassword?: boolean;
  length?: number;
  placeholder?: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
};

export const OtpField = forwardRef<OtpRef, OtpFieldProps>(
  (
    {
      allowedCharacters = "alphanumeric",
      ariaLabel,
      autoFocus = true,
      invalid = false,
      disabled,
      isPassword = false,
      length = 6,
      placeholder,
      onChange,
      onSubmit,
    },
    ref,
  ) => {
    const theme = useTheme();

    if (Number.isNaN(length) || length < 1) {
      throw new Error("Length should be a number and greater than 0");
    }

    if (!allowedCharactersValues.some((value) => value === allowedCharacters)) {
      throw new Error(
        "Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric",
      );
    }

    const inputsRef = useRef<HTMLInputElement[]>([]);
    const inputProps = propsMap[allowedCharacters];

    const submit = () => {
      const value = inputsRef.current.map((input) => input.value).join("");

      if (onChange) {
        onChange(value);
      }

      if (onSubmit && value.length === length) {
        onSubmit(value);
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputsRef.current) {
          inputsRef.current[0].focus();
        }
      },
      clear: () => {
        if (inputsRef.current) {
          for (let i = 0; i < inputsRef.current.length; i += 1) {
            inputsRef.current[i].value = "";
          }
          inputsRef.current[0].focus();
        }
        submit();
      },
    }));

    useEffect(() => {
      if (autoFocus) {
        inputsRef.current[0].focus();
      }
    }, []);

    const handleOnChange = (
      e: ChangeEvent<HTMLInputElement>,
      index: number,
    ) => {
      const {
        target: { value },
      } = e;
      const nextInput = inputsRef.current?.[index + 1];

      if (value.length > 1) {
        e.target.value = value.charAt(0);
        if (nextInput) {
          (nextInput as HTMLInputElement).focus();
        }
      } else if (value.match(inputProps.pattern)) {
        if (nextInput) {
          (nextInput as HTMLInputElement).focus();
        }
      } else {
        e.target.value = "";
      }
      submit();
    };

    const handleOnKeyDown = (
      e: KeyboardEvent<HTMLInputElement>,
      index: number,
    ) => {
      const { key } = e;
      const target = e.target as HTMLInputElement;
      const previousInput = inputsRef.current?.[index - 1];

      if (key === "Backspace") {
        if (target.value === "") {
          if (previousInput) {
            const t = previousInput as HTMLInputElement;
            t.value = "";
            t.focus();
            e.preventDefault();
          }
        } else {
          target.value = "";
        }
        submit();
      }
    };

    const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
      e.target.select();
    };

    const handleOnPaste = (e: ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = e.clipboardData.getData("Text");

      let currentInput = 0;

      for (let i = 0; i < pastedValue.length; i += 1) {
        const pastedCharacter = pastedValue.charAt(i);
        const currentValue = inputsRef.current[currentInput].value;
        if (pastedCharacter.match(inputProps.pattern)) {
          if (!currentValue) {
            inputsRef.current[currentInput].value = pastedCharacter;
            if (inputsRef.current[currentInput + 1]) {
              (inputsRef.current[currentInput + 1] as HTMLInputElement).focus();
              currentInput += 1;
            }
          }
        }
      }
      submit();

      e.preventDefault();
    };

    return (
      <Stack direction="row" gap={0.5} mb={2} mt={2}>
        {[...Array(length).keys()].map((index) => (
          <TextField
            key={index}
            autoComplete={index === 0 ? "one-time-code" : "false"}
            autoFocus={index === 0}
            disabled={disabled}
            error={invalid}
            inputProps={{
              style: {
                fontSize: theme.spacing(4),
                textAlign: "center",
                fontWeight: 600,
                padding: theme.spacing(1),
              },
              maxLength: 1,
              "aria-label": ariaLabel
                ? `${ariaLabel}. Character ${index + 1}.`
                : `Character ${index + 1}.`,
              "data-testid": `mfacode-input-${index}`,
            }}
            inputRef={(el: HTMLInputElement) => {
              inputsRef.current[index] = el;
            }}
            placeholder={placeholder}
            type={isPassword ? "password" : inputProps.type}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleOnChange(e, index)
            }
            onFocus={handleOnFocus}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
              handleOnKeyDown(e, index)
            }
            onPaste={handleOnPaste}
          />
        ))}
      </Stack>
    );
  },
);

OtpField.displayName = "Otp";
