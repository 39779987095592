import { MimeType } from "typings/MimeType";

export const ALLOWED_MIME_TYPES_IMAGE = [
  MimeType.ImageAvif,
  MimeType.ImageJpeg,
  MimeType.ImagePng,
  MimeType.ImageWebp,
];

export const ALLOWED_MIME_TYPES = [
  MimeType.ApplicationPdf,
  MimeType.MicrosoftExcel,
  MimeType.MicrosoftExcelOpenXML,
  MimeType.MicrosoftWord,
  MimeType.MicrosoftWordOpenXML,
  ...ALLOWED_MIME_TYPES_IMAGE,
];

export const MAX_OWNERSHIP_DOCUMENTS = 25;
