import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { usePublishMessageOnNavigation } from "hooks/useNavigation";
import { Entity } from "typings/Entity";
import { ListView } from "typings/ListView";
import {
  CollectorTopics,
  DialogTopics,
  ListingTopics,
  VehicleTopics,
} from "typings/Topics";
import { Vehicle } from "typings/Vehicle";
import { Wishlist } from "typings/Wishlist";

const EditVehicleModal = lazy(
  () => import("components/Vehicle/dialogs/EditVehicleModal"),
);
const EditGalleryModal = lazy(
  () => import("components/EditGalleryModal/EditGalleryModal"),
);
const ListingModal = lazy(
  () => import("components/Listing/dialogs/ListingModal"),
);
const SaveListViewDialog = lazy(
  () => import("components/Vehicle/dialogs/SaveListViewDialog"),
);
const VehicleManageCollectionsModal = lazy(
  () => import("./VehicleManageCollectionsModal"),
);
const VehicleUpdateLocationModal = lazy(
  () => import("components/Vehicle/dialogs/VehicleUpdateLocationModal"),
);
const VehicleUpdateConsignmentModal = lazy(
  () => import("components/Vehicle/dialogs/VehicleUpdateConsignmentModal"),
);
const AddVehicleModal = lazy(
  () => import("components/Vehicle/dialogs/AddVehicleModal"),
);
const ExternalLinksModal = lazy(
  () => import("components/Vehicle/detail/ExternalLinksModal"),
);
const EditVehicleSpecsModal = lazy(
  () => import("components/Vehicle/dialogs/EditVehicleSpecsModal"),
);
const VehicleStatusModal = lazy(
  () => import("components/Vehicle/dialogs/VehicleStatusModal"),
);
const TagsModal = lazy(() => import("components/Vehicle/dialogs/TagsModal"));
const ImportVehiclesModal = lazy(
  () => import("components/Vehicle/dialogs/ImportVehiclesModal"),
);
const TransferVehicleDialog = lazy(
  () => import("components/Vehicle/dialogs/TransferVehicleDialog"),
);
const AssignOrganizationModal = lazy(
  () => import("components/Vehicle/dialogs/AssignOrganizationModal"),
);

export const VehicleDialogs = () => {
  const { object: toEditDetails, handleClose: handleCloseEditDetails } =
    useModalWithSubscription<Vehicle["id"]>(
      DialogTopics.EditVehicle,
      VehicleTopics.Updated,
    );

  const { object: toAdd, handleClose: handleCloseAdd } =
    useModalWithSubscription<{
      ownerId: string;
      vehicleOverQuota: boolean;
      wishlist: Wishlist;
    }>(DialogTopics.AddVehicle, VehicleTopics.Added);

  const { object: toManageCollection, handleClose: handleCloseManage } =
    useModalWithSubscription<Vehicle>(
      DialogTopics.ManageVehicleCollections,
      VehicleTopics.CollectionsUpdated,
    );

  const { object: toUpdateLocation, handleClose: handleCloseUpdateLocation } =
    useModalWithSubscription<Vehicle>(
      DialogTopics.UpdateVehicleLocation,
      VehicleTopics.LocationUpdated,
    );

  const {
    object: toUpdateConsignment,
    handleClose: handleCloseUpdateConsignment,
  } = useModalWithSubscription<Vehicle>(
    DialogTopics.UpdateVehicleConsignment,
    VehicleTopics.ConsignmentUpdated,
  );

  const { object: listView, handleClose: handleCloseSaveListViewAs } =
    useModalWithSubscription<ListView>(
      DialogTopics.SaveListViewAs,
      CollectorTopics.ListViewUpdated,
    );

  const { object: toCreateListing, handleClose: handleCloseCreateListing } =
    useModalWithSubscription<Vehicle["id"]>(
      DialogTopics.AddListing,
      ListingTopics.Updated,
    );

  const { object: toEditGallery, handleClose: handleCloseEditGallery } =
    useModalWithSubscription<Entity>(DialogTopics.EditVehicleGallery);

  const { object: toEditSpecs, handleClose: handleCloseEditSpecs } =
    useModalWithSubscription<Entity>(
      DialogTopics.EditVehicleSpecs,
      VehicleTopics.Updated,
    );

  const {
    object: toEditExternalLinks,
    handleClose: handleCloseEditExternalLinks,
  } = useModalWithSubscription<Vehicle["id"]>(
    DialogTopics.EditVehicleExternalLinks,
    VehicleTopics.ExternalLinksUpdated,
  );

  const { object: toEditStatus, handleClose: handleCloseEditStatus } =
    useModalWithSubscription<Vehicle>(
      DialogTopics.EditVehicleStatus,
      VehicleTopics.StatusUpdated,
    );

  const { object: toImportVehicles, handleClose: handleCloseImportVehicles } =
    useModalWithSubscription<Entity & { vehicleOverQuota: boolean }>(
      DialogTopics.ImportVehicles,
      VehicleTopics.VehiclesImported,
    );

  const { object: toEditTags, handleClose: handleCloseEditTags } =
    useModalWithSubscription<Vehicle["id"]>(
      DialogTopics.EditTags,
      VehicleTopics.TagsUpdated,
    );

  const { object: toTransfer, handleClose: handleCloseTransfer } =
    useModalWithSubscription<Vehicle>(DialogTopics.TransferVehicle);

  const {
    object: toAssignOrganization,
    handleClose: handleCloseAssignOrganization,
  } = useModalWithSubscription<Vehicle>(DialogTopics.AssignOrganization);

  usePublishMessageOnNavigation();

  return (
    <div data-testid="vehicle-dialogs">
      <>
        {!!toAdd && (
          <SuspenseWrapper>
            <AddVehicleModal {...toAdd} onClose={handleCloseAdd} />
          </SuspenseWrapper>
        )}

        {!!toEditDetails && (
          <SuspenseWrapper>
            <EditVehicleModal
              id={toEditDetails}
              onClose={handleCloseEditDetails}
            />
          </SuspenseWrapper>
        )}

        {toManageCollection?.id && (
          <SuspenseWrapper>
            <VehicleManageCollectionsModal
              vehicleId={toManageCollection.id}
              onClose={handleCloseManage}
            />
          </SuspenseWrapper>
        )}

        {toUpdateLocation && (
          <SuspenseWrapper>
            <VehicleUpdateLocationModal
              vehicle={toUpdateLocation}
              onClose={handleCloseUpdateLocation}
            />
          </SuspenseWrapper>
        )}

        {toUpdateConsignment && (
          <SuspenseWrapper>
            <VehicleUpdateConsignmentModal
              vehicle={toUpdateConsignment}
              onClose={handleCloseUpdateConsignment}
            />
          </SuspenseWrapper>
        )}

        {listView && (
          <SuspenseWrapper>
            <SaveListViewDialog
              record={listView}
              onClose={handleCloseSaveListViewAs}
            />
          </SuspenseWrapper>
        )}

        {toCreateListing && (
          <SuspenseWrapper>
            <ListingModal
              vehicleId={toCreateListing}
              onClose={handleCloseCreateListing}
            />
          </SuspenseWrapper>
        )}

        {toEditGallery && (
          <SuspenseWrapper>
            <EditGalleryModal
              {...toEditGallery}
              entityType="vehicle"
              onClose={handleCloseEditGallery}
            />
          </SuspenseWrapper>
        )}

        {toEditSpecs && (
          <SuspenseWrapper>
            <EditVehicleSpecsModal
              {...toEditSpecs}
              onClose={handleCloseEditSpecs}
            />
          </SuspenseWrapper>
        )}

        {toEditExternalLinks && (
          <SuspenseWrapper>
            <ExternalLinksModal
              vehicleId={toEditExternalLinks}
              onClose={handleCloseEditExternalLinks}
            />
          </SuspenseWrapper>
        )}

        {toEditStatus && (
          <SuspenseWrapper>
            <VehicleStatusModal
              vehicle={toEditStatus}
              onClose={handleCloseEditStatus}
            />
          </SuspenseWrapper>
        )}

        {toImportVehicles && (
          <SuspenseWrapper>
            <ImportVehiclesModal
              {...toImportVehicles}
              onClose={handleCloseImportVehicles}
            />
          </SuspenseWrapper>
        )}

        {toEditTags && (
          <SuspenseWrapper>
            <TagsModal vehicleId={toEditTags} onClose={handleCloseEditTags} />
          </SuspenseWrapper>
        )}

        {toTransfer && (
          <SuspenseWrapper>
            <TransferVehicleDialog
              vehicle={toTransfer}
              onClose={handleCloseTransfer}
            />
          </SuspenseWrapper>
        )}

        {toAssignOrganization && (
          <SuspenseWrapper>
            <AssignOrganizationModal
              vehicleId={toAssignOrganization.id}
              onClose={handleCloseAssignOrganization}
            />
          </SuspenseWrapper>
        )}
      </>
    </div>
  );
};
